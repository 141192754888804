@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Variables
// - Colors
$color-cool-slate: #141e28;
$color-cool-slate-nav: #39414a;
$color-tangerine: #ea723d;
$color-cooper: #b7b09d;
$color-celadon: #b2e4e3;
$color-flour: #f3f3f5;
// - Content sizes
$size-border-radius-lg: 4.375rem;
$size-border-radius-sm: 1.875rem;
$size-header-height: 6rem;
$size-inner-padding: 6rem;
$size-outer-padding: 4rem;
$size-main-content-width: 1400px;
// - Media breakpoints
$breakpoint-xl: 1400px;
$breakpoint-lg: 1030px;
$breakpoint-md: 820px;
$breakpoint-sm: 520px;
$breakpoint-xs: 380px;

// Font faces
@font-face {
  font-family: 'TT Hoves Pro Regular';
  src: local('TT Hoves Pro Regular'), local('TTHovesPro-Rg'), url('/assets/fonts/TT_Hoves_Pro_Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'TT Hoves Pro Medium';
  src: local('TT Hoves Pro Medium'), local('TTHovesPro-Md'), url('/assets/fonts/TT_Hoves_Pro_Medium.woff2');
  font-display: swap;
}

// Mixins
// - Media query mixins
@mixin breakpoint-main-content-width {
  @media (max-width: $size-main-content-width) {
    @content;
  }
}

@mixin breakpoint-greater-than-xl {
  @media (min-width: $breakpoint-xl + 1) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media (max-width: $breakpoint-xl) {
    @content;
  }
}

@mixin breakpoint-greater-than-lg {
  @media (min-width: $breakpoint-lg + 1) {
    @content;
  }
}

@mixin breakpoint-lg {
  // Breakpoint for the hamburger menu and tablet layout
  @media (max-width: $breakpoint-lg) {
    @content;
  }
}

@mixin breakpoint-md {
  @media (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin breakpoint-sm {
  // Breakpoint for mobile layout
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin breakpoint-xs {
  @media (max-width: $breakpoint-xs) {
    @content;
  }
}

@mixin breakpoint-specific($max: 999999px, $min: 0px) {
  @media (max-width: $max) and (min-width: $min) {
    @content;
  }
}

// - Background style mixins
@mixin split-bg-color($color1, $color2, $split-loc: 50%, $angle: 180deg) {
  background: linear-gradient($angle, $color1 0%, $color1 $split-loc, $color2 $split-loc, $color2 100%);
}

@mixin background-pattern($color: transparent, $img, $offset-bottom: 0) {
  background: $color $img bottom $offset-bottom right no-repeat;

  @include breakpoint-sm {
    background-image: none;
  }
}

// - Layout style mixins
@mixin flex-init($vertical: false, $between: false, $gap: 0) {
  display: flex;
  gap: $gap;

  @if $vertical != false {
    flex-direction: column;
  } @else {
    flex-direction: row;
  }

  @if $between != false {
    justify-content: space-between;
  } @else {
    justify-content: flex-start;
  }
}

:root {
  // CSS custom properties are defined for each non-static SCSS variable to enable value re-assignments at runtime.
  --size-main-content-width: #{$size-main-content-width};
  --size-header-height: #{$size-header-height};
  --size-border-radius: #{$size-border-radius-lg};

  // Width must be kept in sync with header component's breakpoint observer
  @include breakpoint-specific($max: 1350px) {
    &,
    header,
    .extended-menu {
      --size-header-height: 4.125rem;
    }
  }

  @include breakpoint-sm {
    --size-border-radius: #{$size-border-radius-sm};
  }
}

* {
  box-sizing: border-box;
}

body,
html,
:root {
  width: 100%;
  overflow: auto;
}

body {
  position: relative;
  margin: 0;
  background-color: $color-cool-slate;
  color: $color-flour;
  font-family: 'TT Hoves Pro Regular', sans-serif;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
}

.no-scroll {
  overflow-y: hidden !important; // Ensuring it overrides other styles
}

// Header text
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

h1,
h2,
h3,
h6,
.h1,
.h2,
.h3,
.h6 {
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: 4rem;

  @include breakpoint-lg {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: 3.5rem;

  @include breakpoint-lg {
    font-size: 2.125rem;
    line-height: 2.25rem;
  }
}

h3,
.h3 {
  font-size: 2.5rem;
  line-height: 2.625rem;

  @include breakpoint-lg {
    font-size: 1.875rem;
    line-height: 2rem;
  }
}

h4,
.h4 {
  font-size: 1.875rem;
  line-height: 2.125rem;

  @include breakpoint-lg {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
}

h5,
.h5 {
  font-size: 1.5rem;
  line-height: 1.875rem;

  @include breakpoint-lg {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 1px;

  @include breakpoint-lg {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.title span.highlighted {
  color: $color-tangerine;
}

// Normal body text
p,
b,
i,
label {
  margin: 0;
}

b,
strong {
  font-family: 'TT Hoves Pro Medium', sans-serif;
}

.text-big {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-med {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.text-small {
  font-size: 1rem;
  line-height: 1.375rem;
}

.text-x-small {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.subtitle {
  opacity: 0.7;
}

.title + .subtitle {
  width: auto;
  margin-left: $size-inner-padding;

  @include breakpoint-md {
    width: 100% !important;
    margin: 0 0 0.75rem;
  }

  + app-button {
    margin-left: $size-inner-padding;

    @include breakpoint-md {
      margin: 0;
    }
  }
}

// Links
a,
.link {
  position: relative;
  width: fit-content;
  outline: none;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  white-space: nowrap;
  @extend .text-med;

  &:not(.no-animate):not(:focus-visible) {
    &::before,
    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      height: 1px;
      width: 100%;
      background: currentColor;
      pointer-events: none;
    }

    &::before {
      content: '';
      transform: scale3d(0, 1, 1);
      transform-origin: 100% 50%;
      transition: transform 0.3s ease-in-out;
    }

    &:hover,
    &.active {
      outline: none;
      opacity: 1 !important;

      &::before {
        transform: scale3d(1, 1, 1);
        transform-origin: 0 50%;
      }
    }
  }

  &:focus {
    // Provide a fallback style for browsers that don't support :focus-visible
    // https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
    outline: none;
    background: lightgrey;

    &-visible {
      // Draw a very noticeable focus style for keyboard-focus on browsers that support :focus-visible
      border-radius: 1px;
      outline: 2px solid $color-tangerine;
      background: transparent;
    }

    &:not(:focus-visible) {
      // Remove the focus indicator on mouse-focus for browsers that support :focus-visible
      background: transparent;
    }
  }

  &.med {
    @extend h5;
  }

  &.small {
    @extend .text-small;
  }

  &.x-small {
    @extend .text-x-small;
  }

  &.inactive {
    opacity: 0.7;
  }

  &.dim {
    opacity: 0.4;
  }
}

// Images
img {
  object-fit: cover;

  &.rounded {
    border-bottom-left-radius: var(--size-border-radius);
    border-top-right-radius: var(--size-border-radius);
  }

  &.x-tall {
    aspect-ratio: 1 / 2;
  }

  &.tall {
    aspect-ratio: 4 / 5;
  }

  &.square {
    aspect-ratio: 1 / 1;
  }

  &.wide {
    aspect-ratio: 5 / 4;
  }

  &.wider {
    aspect-ratio: 3 / 2;
  }

  &.x-wide {
    aspect-ratio: 2 / 1;
  }
}

// Forms
form {
  .form-field {
    &.required {
      label::after {
        padding-left: 0.3rem;
        color: $color-tangerine;
        content: '*';
      }
    }

    label {
      @extend h6;
    }

    .input-field {
      border-radius: 0.5rem;
      box-sizing: border-box;
      width: 100%;
      margin-top: 0.5rem;
      outline: 1px solid transparent;
      padding: 0.75rem 0.94rem;
      background: white;
      resize: none;
      transition: outline 0.3s;

      &:hover {
        outline: 1px solid $color-cool-slate-nav;
      }

      &:focus,
      &:focus-visible {
        outline: 1px solid $color-tangerine;
      }
    }

    select {
      cursor: pointer !important;
    }
  }
}

// Inner-pages per route
.inner-page {
  padding: $size-inner-padding $size-outer-padding $size-inner-padding;

  .content {
    width: 100%;
    margin: 0 auto 0;
    max-width: var(--size-main-content-width);

    &.card {
      border-bottom-left-radius: var(--size-border-radius);
      border-top-right-radius: var(--size-border-radius);
      overflow: hidden;
      padding: 5rem 7rem;
      background-color: $color-flour;

      * {
        color: $color-cool-slate;
      }

      @include breakpoint-lg {
        padding: 3.125rem 2.25rem;
      }

      @include breakpoint-md {
        padding: 3.125rem 1.25rem;
      }
    }
  }

  .basic-content-with-subtitle {
    @include flex-init($gap: 4rem);

    .content-group {
      @include flex-init($vertical: true, $gap: 2rem);
      width: 50%;
      justify-content: center;
    }

    .org-top {
      aspect-ratio: 1 / 1;
    }

    .subtitle {
      width: 62%;
    }

    @include breakpoint-md {
      width: 100%;
      gap: 2rem;

      .content-group {
        width: 100%;
      }
    }

    @include breakpoint-sm {
      flex-direction: column;
      gap: 2rem;

      &.img-right {
        flex-direction: column-reverse;
      }

      .content-group {
        width: auto;
        gap: 1rem;

        .subtitle {
          width: 100%;
        }
      }
    }
  }

  &.full-width-img {
    padding: 0;

    img {
      width: 100%;
    }
  }

  @include breakpoint-lg {
    padding: 2.5rem 1.125rem;
  }
}

@mixin rows-with-vertical-background-pattern(
  $_color-behind-content: transparent,
  $_color-foreground: $color-flour,
  $_color-background: $color-cool-slate,
  $_color-accent: $color-flour,
  $_color-background-pattern: 'tangerine',
  $_rounded-top-right-corner: false
) {
  padding: 0;
  background-color: $_color-behind-content;
  color: $_color-foreground;

  .content-bg {
    @include background-pattern(
      $color: $_color-background,
      $img: url('/assets/patterns/pattern-outline-#{$_color-background-pattern}.png'),
      $offset-bottom: -8rem
    );
    @if $_rounded-top-right-corner {
      border-top-right-radius: var(--size-border-radius);
    }
    padding: $size-inner-padding $size-outer-padding;
  }

  .content {
    @include flex-init($vertical: true, $gap: $size-inner-padding * 0.5);
  }

  .title {
    color: $_color-accent;

    &:first-of-type {
      color: $_color-foreground;
    }
  }

  .titles + .subtitle {
    width: calc(50% - $size-inner-padding);
    margin-left: $size-inner-padding;
  }

  .row {
    border-top: 1px solid rgba($_color-foreground, 0.2);
    display: flex;
    width: calc(80% - $size-inner-padding);
    margin-left: $size-inner-padding;
    padding: 2.5rem 1.25rem 0 0;
    gap: 2rem;
  }

  .content-group {
    width: 50%;
  }

  @include breakpoint-xl {
    .content-bg .content .row {
      gap: 1.25rem;

      .content-group.left .mini-title {
        font-size: clamp(1.5rem, 3vw, 3rem);
      }
    }
  }

  @include breakpoint-lg {
    .content-bg {
      padding: 2.5rem 1.25rem;
    }
  }

  @include breakpoint-md {
    .content-bg .content {
      gap: 1.25rem;

      .titles + .subtitle {
        width: auto;
        margin: 0 auto 0.75rem 0;
      }

      .row {
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding: 1.25rem 1.25rem 0 1.25rem;
        gap: 0.5rem;

        .content-group {
          width: 100%;
        }
      }
    }
  }
}

// Dividers
.divider {
  background-color: $color-cool-slate;
  opacity: 0.2;

  &.light {
    background-color: $color-flour;
  }

  &.vertical {
    height: 100%;
    width: 1px;

    &.thick {
      width: 2px;
    }
  }

  &.horizontal {
    height: 1px;
    width: 100%;

    &.thick {
      height: 2px;
    }
  }
}

// OneTrust cookie management
#onetrust-banner-sdk .ot-sdk-container {
  position: relative !important;
  margin: 0 !important;
  overflow: hidden !important;
  background: #b7b09d !important;
  font-family: 'TT Hoves Pro Regular', 'Inter', Arial, sans-serif !important;
  --_button-container-gap: 1rem;
  --_button-padding-horizontal: 2rem;
  --_content-flex-direction: row;
  --_content-gap: 7rem;
  --_content-padding-horizontal: 2rem;
  --_content-padding-vertical: 2.5rem;

  // Flex content container
  .ot-sdk-row {
    display: flex !important;
    flex-direction: var(--_content-flex-direction) !important;
    margin: auto !important;
    max-width: $size-main-content-width !important;
    padding: var(--_content-padding-vertical) var(--_content-padding-horizontal) !important;
    gap: var(--_content-gap) !important;

    &::after {
      display: none !important;
    }

    * {
      margin: 0 !important;
      color: #141e28 !important;
    }

    > div {
      width: 50% !important;
      max-width: 50% !important;
    }
  }

  // Text container
  #onetrust-group-container #onetrust-policy-text {
    * {
      font-size: 1.125rem !important;
      line-height: 1.5rem !important;
    }

    span {
      display: block !important;
      margin-bottom: 0.66rem !important;
      letter-spacing: 0.0625rem !important;
      text-transform: uppercase !important;
    }
  }

  // Buttons container
  #onetrust-button-group-parent {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    padding: 0 !important;
    transform: unset !important;

    #onetrust-button-group {
      align-items: flex-start !important;
      display: flex !important;
      flex-direction: column !important;
      height: 100% !important;
      justify-content: flex-start !important;
      gap: var(--_button-container-gap) !important;

      button {
        border: 2px solid #f3f3f5 !important;
        border-radius: 10rem !important;
        width: auto !important;
        margin: 0 !important;
        outline: 2px solid transparent !important;
        outline-offset: -1px !important;
        padding: 1rem var(--_button-padding-horizontal) !important;
        background: transparent !important;
        font-family: 'TT Hoves Pro Regular', 'Inter', Arial, sans-serif !important;
        line-height: normal !important;
        cursor: pointer !important;
        text-transform: uppercase !important;
        transition: outline 0.2s !important;
        white-space: break-spaces !important;

        &:hover,
        &:focus {
          outline-color: #f3f3f5 !important;
          text-decoration: underline !important;
        }

        &:focus-visible {
          outline-color: #ea723d !important;
        }

        &:nth-of-type(2) {
          background: #f3f3f5 !important;
        }
      }
    }
  }

  // Close button container
  #onetrust-close-btn-container {
    position: absolute !important;
    top: var(--_content-padding-vertical) !important;
    right: var(--_content-padding-horizontal) !important;
    align-content: center !important;
    display: flex !important;
    height: 1rem !important;
    width: 1rem !important;
    justify-content: center !important;

    a.onetrust-close-btn-ui.onetrust-lg {
      border: 0 !important;
      height: 100% !important;
      width: 100% !important;
      background-color: transparent !important;
      background-size: contain !important;
      cursor: pointer !important;
      transition: all 0.2s !important;

      &:hover,
      &:focus {
        transform: scale(1.2) !important;
      }

      &::before,
      &::after {
        display: none !important;
      }
    }
  }

  @include breakpoint-lg {
    --_content-padding-vertical: 1.5rem;
    --_content-padding-horizontal: 1.25rem;
    --_content-gap: 1.5rem;
    --_button-padding-horizontal: 1.5rem;
    --_content-flex-direction: column;

    .ot-sdk-row {
      padding-right: 10% !important;

      > div {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

// OneTrust cookie preferences modal
#onetrust-pc-sdk.otPcCenter {
  min-height: 60vh;
}
